import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import {
  PV_USER_SERVICE,
  PvUserService,
} from '@pv-frontend/pv-shared-services/pv-user-service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'axis-ol-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  public isLoggedIn: boolean = false;
  private destroy$: Subject<void> = new Subject<void>();

  public constructor(
    @Inject(PV_USER_SERVICE) private pvUserService: PvUserService,
    private router: Router
  ) {
    this.subscribeUserLogin();
  }

  public handleBack(): void {
    this.router.navigate(['']);
  }

  public handleLogout(): void {
    this.pvUserService.logoutUser();
  }

  private subscribeUserLogin(): void {
    this.isLoggedIn = this.pvUserService.isUserAuthenticated();
    this.pvUserService.userLoggedInObservable$
      .pipe(takeUntil(this.destroy$))
      .subscribe((isLoggedIn: boolean) => {
        this.isLoggedIn = isLoggedIn;
      });
  }
}

export type IgnoreRule = {
  status?: number;
  message?: string;
};

export type ErrorHint = {
  originalException?: {
    status?: number;
    message?: string;
  };
};

export type EventHint = {
  originalException?: {
    status?: number;
    message?: string;
  };
};

export const ignoredErrors: IgnoreRule[] = [
  { status: 504, message: '504 Gateway Timeout' },
];

const messagePatterns: string[] = ['r\\.DomainData', 'some-other-error'];

export const combinedMessageRegex = new RegExp(messagePatterns.join('|'), 'i');

import { ContextualGlobalDisplayConfig } from '@pv-frontend/contextual-journey';

import { redeemButtonStyle } from './buttons.config';
import { panelStyles } from './gift-vouchers';

export const contextualMobileJourneyConfig: ContextualGlobalDisplayConfig = {
  global: {
    config: {
      benefitDefaultImage:
        'https://images.poshvine.com/b/default-thumbnail-square.jpg',
    },
    style: {},
  },
  detailPage: {
    accordion: {
      styles: {
        boxShadow: 'unset',
        borderRadius: '0',
        marginBottom: '.5rem',
      },
      config: {},
    },
    accordionIcon: {
      styles: {
        width: '1.2rem',
        height: '1.2rem',
        fill: 'var(--primary-blue)',
      },
      config: {},
    },
    claimButton: {
      styles: {
        ...redeemButtonStyle,
      },
    },
    knowMore: {
      styles: {
        fontWeight: 700,
      },
    },
  },
  redeemedVoucherCardConfig: {
    voucherCard: {
      container: {
        header: {
          image: {
            imgURL:
              'https://images.poshvine.com/logos/jana-small-finance-bank-limited-logo-vector 1.svg',
            styles: {
              width: '145px',
              height: '56px',
            },
          },
          closeIcon: {
            styles: {
              fill: '#D6DFE8',
              height: '22px',
              width: '22px',
            },
          },
        },
        voucherExpiry: {
          label: 'Expires on',
        },
        tAndC: {
          text: 'Please refer to the Offer Terms and Conditions on how to redeem this offer',
        },
        giftCardVoucherSuccessContainer: {
          successIcon: {
            imgURL: 'https://images.poshvine.com/icon/star-tick.svg',
          },
          successText: {
            label: 'We have received your order',
          },
          successDescription: {
            label:
              'Your gift card code may take up to a few mins to be processed. Please bear with us. We will send you the code via email and/or sms.',
          },
          redemptionButton: {
            styles: { fontSize: '12px', ...redeemButtonStyle },
          },
        },
        redeemButton: {
          styles: { fontSize: '12px' },
        },
      },
    },
    checkRedemptionNavigationPath: ['order-history'],
  },
  knowMorePage: {
    accordion: {
      styles: panelStyles,
    },
    accordionIcon: {
      styles: {
        width: '1.2rem',
        height: '1.2rem',
        fill: 'var(--primary-blue)',
      },
    },
    backButton: {
      styles: {
        fill: 'var(--surface-a)',
        background: 'rgba(0, 0, 0, 0.1)',
        width: '32px',
        height: '32px',
        padding: '6px 8px',
        borderRadius: '50%',
      },
    },
    merchantName: {
      styles: {
        fontSize: '1.143rem',
        fontWeight: '600',
        color: 'var(--text-color)',
        marginBottom: '0.857rem',
      },
    },
    benefitTitle: {
      styles: {
        fontSize: '1rem',
        color: 'var(--gray-secondary)',
      },
    },
  },
  merchantListDisplay: {},
  giftTableConfigOverride: {},
};

export const contextualDektopJourneyConfig: ContextualGlobalDisplayConfig = {
  global: {
    config: {
      benefitDefaultImage:
        'https://images.poshvine.com/b/default-thumbnail-square.jpg',
    },
    style: {},
  },
  redeemedVoucherCardConfig: {
    ...contextualMobileJourneyConfig.redeemedVoucherCardConfig,
  },
  detailPage: {
    accordion: {
      styles: {
        boxShadow: 'unset',
        borderRadius: '0',
        marginBottom: '.5rem',
      },
    },
    accordionIcon: {
      styles: {
        width: '1.2rem',
        height: '1.2rem',
        fill: 'var(--primary-blue)',
      },
    },
    backButtonImage: {
      styles: { fill: 'var(--primary-color)' },
    },
  },
  knowMorePage: contextualMobileJourneyConfig.knowMorePage,
  merchantListDisplay: {},
  giftTableConfigOverride: {},
};

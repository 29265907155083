<footer>
  <div class="flex flex-column justify-content-center p-5 content-width">
    <div class="flex justify-content-center">
      <span>Call us on</span>
      <span class="phone-number ml-1 font-bold">
        <a href="tel:+18004190065" class="no-underline text-primary">
          1800 419 0065
        </a>
      </span>
    </div>
    <div class="flex justify-content-center mt-2">
      <div class="text-primary font-semibold text-xs">
        <a href="info/terms-and-conditions" class="text-primary">
          Terms and Conditions
        </a>
      </div>
      <div class="text-primary font-semibold ml-2 text-xs">
        <a href="/info/privacy-policy" class="text-primary">Privacy Policy</a>
      </div>
    </div>
  </div>
  <div class="w-full justify-content-center flex p-3 surface-border">
    <img
      src="https://images.poshvine.com/common/pci-dss.svg"
      alt="pci"
      class="pci" />
  </div>
  <div
    class="flex pv-mt-22px font-light align-items-center justify-content-center mb-5 powered-text font-normal">
    Powered by
    <a class="razorpay-logo" href="http://razorpay.com/" target="_blank">
      <img
        alt="razorpay-logo"
        class="razorpay-logo pv-ml-7px md:pv-ml-5px"
        src="https://images.poshvine.com/razorpay/razorpay_blue_logo.svg" />
    </a>
  </div>
</footer>

<div class="header-wrapper flex justify-content-center">
  <div class="header-container">
    <div
      class="flex align-items-center py-3 header-content"
      [ngClass]="{
        'md:justify-content-start': !isLoggedIn
      }">
      <button
        (click)="handleBack()"
        class="p-2 border-none m-0 flex border-0 logo-container cursor-pointer">
        <img
          src="https://images.poshvine.com/axis-olympus/axis-bank-logo.svg"
          class="logo"
          alt="axis bank logo" />
      </button>

      <pv-button
        label="Logout"
        styleClass="flex flex-row-reverse justify-content-center gap-2 logout-text"
        *ngIf="isLoggedIn"
        (clickEvent)="handleLogout()"
        class="ml-auto">
        <pv-svg-icon name="exit" class="exit-icon"></pv-svg-icon>
      </pv-button>
    </div>
  </div>
</div>

import { EnvInterface } from './env-interface';

export const environment: EnvInterface = {
  production: true,
  sentryEnvName: 'production',
  apiPerksServiceUrl: 'https://api-customer.poshvine.com/ps/v1',
  apiPerksServiceUrlV2: 'https://api-customer.poshvine.com/ps/v2',
  apiClientsServiceUrl: 'https://api-customer.poshvine.com/cs/v1',
  apiUrlPaV1: 'https://api-customer.poshvine.com/pa/v1/',
  apiBookingServiceUrl: 'https://api-customer.poshvine.com/bs/v1',
  clientId: 'aaeddafe-b225-481d-9c67-98761f212f63',
  projectId: '3d2c23ce-c078-4c20-ab51-08b20fc4dbc9',
  apiUrlBsV1: 'https://api-customer.poshvine.com/bs/v1/',
  apiUrlBsV1Internal: 'https://api-customer.poshvine.com/bs/internal',
  apiUrlLsV1: 'https://api-customer.poshvine.com/ls/v1/',
  corePath: 'https://api-customer-offerservice.poshvine.com/core_service/v1',
  corePathV2: 'https://api-customer.poshvine.com/cs/v1',
  offerPath: 'https://api-customer-offerservice.poshvine.com/offer_service/v1',
  offerPathV2: 'https://api-customer.poshvine.com/ps/v1',
  countryId: '625a3e8e-e493-421a-8ece-609f10b37442',
  campaignPath: 'https://api.poshvine.com/cas/v1/',
  urlsToBeRemoved: ['https://janabank.poshvine.com'],
  apiLoyaltyServiceUrl: 'https://api-customer.poshvine.com/ls/v1',
  apiBookingServiceUrlV2: '',
  projectLink: 'https://axisbankolympuscreditcard.poshvine.com/',
  sentryDsn:
    'https://0d9b077b297605968b2110ce48a5eaa2@o515678.ingest.us.sentry.io/4507457317502976',
};

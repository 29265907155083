import { Component } from '@angular/core';

import { RedemptionDetails } from './interfaces/gv-redemption';
import { CampaignService } from './services/campaign/campaign.service';
@Component({
  selector: 'axis-ol-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  public showRedemptionModal: boolean = false;
  public redemptionObj?: RedemptionDetails;
  public constructor(private campaignService: CampaignService) {
    this.campaignService.redeemGv.subscribe(
      (redemptionObj: RedemptionDetails | false) => {
        if (redemptionObj as RedemptionDetails) {
          this.redemptionObj = redemptionObj as RedemptionDetails;
          this.showRedemptionModal = true;
        } else {
          this.redemptionObj = undefined;
          this.showRedemptionModal = false;
        }
      }
    );
  }
}

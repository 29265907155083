import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';

import packageJson from '../../../package.json';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import {
  ErrorHint,
  EventHint,
  combinedMessageRegex,
  ignoredErrors,
} from './sentryIgnoredErrors';

const shouldIgnoreError = (error?: ErrorHint['originalException']): boolean => {
  if (!error) return false;

  for (const rule of ignoredErrors) {
    if (
      (rule?.status !== undefined && error?.status === rule?.status) ||
      (rule?.message && error?.message?.includes(rule?.message))
    ) {
      return true;
    }
  }
  return error?.message ? combinedMessageRegex?.test(error?.message) : false;
};

Sentry.init({
  dsn: environment.sentryDsn,
  release: packageJson.version,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  environment: environment.sentryEnvName,

  // Performance Monitoring
  tracesSampleRate: 0.2, //  Capture 20% of the transactions
  beforeSend(event, hint) {
    const stacktrace = event.exception?.values?.[0]?.stacktrace?.frames;

    if (
      stacktrace &&
      stacktrace.some((frame) => frame.filename?.includes('node_modules'))
    ) {
      return null;
    }

    return shouldIgnoreError(
      hint?.originalException as EventHint['originalException']
    )
      ? null
      : event;
  },
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

import { GiftVouchersConfig } from '@pv-frontend/gift-voucher-journey';

import { giftVouchersConfig } from './gift-vouchers';

export const giftVouchersDesktopConfig: GiftVouchersConfig = {
  ...giftVouchersConfig,

  landingPage: {
    ...giftVouchersConfig.landingPage,
    categoryAvatarStyle: {
      ...giftVouchersConfig.landingPage.categoryAvatarStyle,
      label: {
        ...giftVouchersConfig?.landingPage.categoryAvatarStyle?.label,
        paddingTop: '8px',
      },
      imageOrInitialsContainer: {
        ...giftVouchersConfig?.landingPage.categoryAvatarStyle
          ?.imageOrInitialsContainer,
        container: {
          width: '113px',
          height: 'auto',
          display: 'flex',
          justifyContent: 'center',
        },
        image: {
          width: '72px',
          height: '72px',
        },
      },
    },
    personalisedCardSectionStyle: {
      style: {
        backgroundImage: {
          position: 'absolute',
          top: '-15px',
          right: '6rem',
          transform: 'rotate(0deg)',
          width: '10rem',
          height: '10rem',
        },
        title: {
          image: {
            fill: 'var(--green-600)',
            marginRight: '.2rem',
            height: '1.5rem',
          },
        },
        card: {
          backgroundColor: '#FCF4E8',
          margin: '1.5rem 0 0',
          padding: '1.5rem 5rem 1.5rem',
        },
      },
    },
    limitedTimeCardSectionStyle: {
      ...giftVouchersConfig.landingPage.limitedTimeCardSectionStyle,
      style: {
        ...giftVouchersConfig?.landingPage?.limitedTimeCardSectionStyle?.style,
        title: {
          ...giftVouchersConfig.landingPage.limitedTimeCardSectionStyle?.style
            ?.title,
          image: {
            marginRight: '.2rem',
            height: '1.5rem',
          },
        },
        link: {
          fontWeight: '700',
          fontSize: '1rem',
        },
        card: {
          backgroundColor: '#fff',
          margin: '0',
          padding: '.3rem 5rem .5rem',
        },
      },
    },
    // TODO: the below code blocks to be further optimized by extending giftVouchersConfig more efficiently
    mostSearchedSectionStyle: {
      ...giftVouchersConfig.landingPage.mostSearchedSectionStyle,
      style: {
        title: {
          container: {
            paddingLeft: '0.8rem',
            fontSize: '1.125rem',
          },
          image: {
            fill: '#2A86F3',
            marginRight: '.2rem',
            height: '1.5rem',
          },
        },
        card: {
          backgroundColor: '#fff',
          margin: '1rem 0 0',
          padding: '0 5rem',
        },
      },
    },
    merchantAvatarStyle: {
      style: {
        label: {
          lineHeight: '1.2rem',
          overflow: 'hidden',
          display: '-webkit-box',
          webkitBoxOrient: 'vertical',
          textOverflow: 'ellipsis',
          whiteSpace: 'normal',
          webkitLineClamp: '1',
          width: '100%',
          fontSize: '1.125rem',
          paddingTop: '.8rem',
        },
        container: {
          paddingBottom: '1rem',
        },
        imageOrInitialsContainer: {
          container: {
            width: '12rem',
            height: 'auto',
            display: 'flex',
            justifyContent: 'center',
          },
          image: {
            width: '5.5rem',
            height: '5.5rem',
          },
        },
      },
    },
    bestSellerCardSectionStyle: {
      style: {
        backgroundImage: {
          position: 'absolute',
          top: '-15px',
          right: '1rem',
          transform: 'rotate(0deg)',
          width: '6rem',
          height: '6rem',
        },
        title: {
          container: {
            paddingLeft: '0.8rem',
          },
          image: {
            fill: 'var(--green-600)',
            marginRight: '.2rem',
            height: '1.5rem',
          },
        },
        link: {
          fontWeight: '700',
          fontSize: '1rem',
        },
        card: {
          backgroundColor: 'rgba(0, 156, 92, 0.09)',
          margin: '0',
          padding: '.6rem 5rem 1.5rem',
        },
      },
    },
    bestSellerCardStyle: {
      style: {
        cardContainer: {
          width: '100% !important',
          height: '100% !important',
          border: '.4px solid var(--feedback-border-positive-high-contrast)',
        },
        title: {
          color: 'var(--surface-text-normal-low-contrast)',
          fontSize: '1.125rem',
        },
        cardContent: {
          padding: '0.75rem 0.75rem 2rem',
        },
        footer: {
          background:
            'linear-gradient(90deg, rgba(0, 140, 177, 0.12) 13.92%, rgba(0, 140, 177, 0) 111.39%)',
          position: 'absolute',
          width: '100%',
          bottom: '0',
          fontSize: '.95rem',
        },
      },
    },
    categoryOfferSectionStyle: {
      ...giftVouchersConfig.landingPage.categoryOfferSectionStyle,
      style: {
        backgroundImage: {
          position: 'absolute',
          top: '-15px',
          right: '1rem',
          transform: 'rotate(0deg)',
          width: '6rem',
          height: '6rem',
        },
        title: {
          image: {
            fill: 'var(--green-600)',
            marginRight: '.2rem',
            height: '1.5rem',
          },
          container: {
            fontSize: '1.125rem',
          },
        },
        link: {
          fontWeight: '700',
          fontSize: '1rem',
        },
        card: {
          backgroundColor: 'var(--background-tertiary-color)',
          margin: '0',
          padding: '.5rem 5rem .5rem',
        },
      },
      config: {
        buttonLabel: 'VIEW ALL',
      },
    },
    categoryOfferCard: {
      style: {
        ...giftVouchersConfig.landingPage.categoryOfferCard?.style,
        title: {
          ...giftVouchersConfig.landingPage.categoryOfferCard?.style?.title,
          fontSize: '1.125rem',
        },
        image: {
          ...giftVouchersConfig.landingPage.categoryOfferCard?.style?.image,
          maxHeight: '10.938rem',
        },
        information: {
          ...giftVouchersConfig.landingPage.categoryOfferCard?.style
            ?.information,
          baseText: {
            ...giftVouchersConfig.landingPage.categoryOfferCard?.style
              ?.information?.baseText,
            fontSize: '15px',
          },
          attentionText: {
            ...giftVouchersConfig.landingPage.categoryOfferCard?.style
              ?.information?.attentionText,
            fontSize: '15px',
          },
        },
      },
    },
    topMembershipSectionStyle: {
      style: {
        backgroundImage: {
          top: '-15px',
          right: '1rem',
          transform: 'rotate(0deg)',
          width: '6rem',
          height: '6rem',
        },
        title: {
          container: {
            fontSize: '1.125rem',
          },
          text: {},
          emphasizedText: {
            fontSize: '1.125rem',
          },
          image: {
            fill: 'var(--blue-600)',
            marginRight: '.2rem',
            height: '1.5rem',
            maxHeight: '10.938rem',
          },
        },
        card: {
          backgroundColor: 'var(--background-secondary-color)',
          padding: '.5rem 5rem .5rem',
          marginBottom: '0px',
          paddingBottom: '2rem',
        },
      },

      config: {
        buttonLabel: 'View All',
        emphasizedTitleText: 'Top Memberships',
      },
    },
    topMembershipCardStyle: {
      style: {
        ...giftVouchersConfig.landingPage.topMembershipCardStyle?.style,
        cardContainer: {
          ...giftVouchersConfig.landingPage.topMembershipCardStyle?.style
            ?.cardContainer,
          maxWidth: '25%',
        },
        title: {
          ...giftVouchersConfig.landingPage.topMembershipCardStyle?.style
            ?.title,
          fontSize: '1rem',
        },
        subTitle: {
          ...giftVouchersConfig.landingPage.topMembershipCardStyle?.style
            ?.subTitle,
          fontSize: '17px',
        },
      },
    },
    topBanner: {
      image: {
        width: '100%',
      },
    },
    offersSectionStyle: {
      style: {
        cardSection: {
          backgroundImage: {
            position: 'absolute',
            top: '-.9rem',
            right: '1rem',
            transform: 'rotate(0deg)',
            width: '7rem',
            height: '7rem',
          },
          title: {
            image: {
              fill: 'var(--green-600)',
              marginRight: '.2rem',
              height: '1.5rem',
            },
            container: {
              fontSize: '1.125rem',
            },
          },
          card: {
            backgroundColor: 'var(--card-background-varient-1)',
            margin: '0 0 0',
            padding: '0.5rem 5rem 1.5rem',
          },
        },
        categoryBasedOffers: {
          card: {
            style: {
              ...giftVouchersConfig.landingPage.offersSectionStyle?.style
                .categoryBasedOffers.card.style,
              image: {
                ...giftVouchersConfig.landingPage.offersSectionStyle?.style
                  .categoryBasedOffers.card.style?.image,
                minHeight: '107px',
                maxHeight: '10.938rem',
              },
              title: {
                ...giftVouchersConfig.landingPage.offersSectionStyle?.style
                  .categoryBasedOffers.card.style?.title,
                fontSize: '17px',
              },
              subTitle: {
                ...giftVouchersConfig.landingPage.offersSectionStyle?.style
                  .categoryBasedOffers.card.style?.subTitle,
                fontSize: '1rem',
              },
              information: {
                baseText: {
                  ...giftVouchersConfig.landingPage.offersSectionStyle?.style
                    .categoryBasedOffers.card.style?.information?.baseText,
                  fontSize: '1rem',
                },
                attentionText: {
                  ...giftVouchersConfig.landingPage.offersSectionStyle?.style
                    .categoryBasedOffers.card.style?.information?.attentionText,
                  fontSize: '1rem',
                },
              },
            },
          },

          chip: {
            style: {
              inActive: {
                container: {
                  width: 'auto',
                  whiteSpace: 'nowrap',
                  border: 'unset',
                  color: 'var(--surface-text-subdued-low-contrast)',
                },
                label: {
                  fontWeight: '700',
                },
              },
              active: {
                container: {
                  width: 'auto',
                  whiteSpace: 'nowrap',
                  border: 'unset',
                  color: 'var(--surface-a)',
                  backgroundColor: 'var(--primary-color)',
                },
                label: {
                  fontWeight: '700',
                },
              },
            },
          },
        },
      },
    },
    banners: {
      config: {
        data: [
          {
            imageUrl:
              'https://images.poshvine.com/banners/Exclusive+-+Taj+Epicure_1084x240+px.webp',
          },
        ],
        showPageTopBanner: true,
        showPageMiddleBanner: true,
      },
    },
  },
  shellListing: {
    ...giftVouchersConfig.shellListing,
    gridContainer: {
      style: {
        justifyContent: 'center',
        justifyItems: 'center',
        alignItems: 'center',
        gridTemplateColumns: 'repeat(auto-fit, minmax(256px, 1fr))',
        display: 'grid',
      },
    },
    card: {
      style: {
        cardContainer: { maxHeight: '360px', height: '360px', width: '256px' },
        cardContent: { minHeight: '143px' },
        image: { height: '180px' },
        title: { fontSize: '18px', fontWeight: 800, color: '#000' },
        subTitle: { fontSize: '16px', fontWeight: 200, color: '#000' },
        information: {
          primaryText: { color: '#008659' },
          baseText: { color: 'var(--primary-color)' },
          attentionText: { color: 'var(--primary-color)' },
        },
      },
    },
  },
  listing: {
    ...giftVouchersConfig.listing,
    card: {
      style: {
        ...giftVouchersConfig.listing.card.style,
        title: {
          ...giftVouchersConfig.listing.card.style?.title,
          fontSize: '1.125rem',
        },
        information: {
          ...giftVouchersConfig.listing.card.style?.information,
          baseText: {
            ...giftVouchersConfig.listing.card.style?.information?.baseText,
            fontSize: '15px',
          },
          attentionText: {
            ...giftVouchersConfig.listing.card.style?.information
              ?.attentionText,
            fontSize: '15px',
          },
        },
        subTitle: {
          ...giftVouchersConfig.listing.card.style?.subTitle,
          fontSize: '1rem',
        },
      },
    },
  },
  orderHistory: {
    ...giftVouchersConfig.orderHistory,
    backArrow: {
      ...giftVouchersConfig.orderHistory.backArrow,
      navigationPath: ['gvms'],
    },
    chip: {
      style: {
        ...giftVouchersConfig.orderHistory.chip.style,
        inActive: {
          ...giftVouchersConfig.orderHistory.chip.style?.inActive,
          container: {
            ...giftVouchersConfig.orderHistory.chip.style?.inActive.container,
            margin: '0 12px 20px 0',
          },
        },
        active: {
          ...giftVouchersConfig.orderHistory.chip.style?.active,
          container: {
            ...giftVouchersConfig.orderHistory.chip.style?.active.container,
            margin: '0 12px 20px 0',
          },
        },
      },
    },
  },
  offerDetails: {
    ...giftVouchersConfig.offerDetails,
    style: {
      ...giftVouchersConfig.offerDetails.style,
      footerContainer: {
        display: 'none',
      },
    },
  },
  benefitDetails: {
    ...giftVouchersConfig.benefitDetails,
    panel: {
      style: {
        container: {
          borderRadius: '1.143rem',
          background: 'var(--surface-a)',
          boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.16)',
          color: 'var(--black-teritiary)',
          overflow: 'hidden',
          marginBottom: '1rem',
          padding: '0.857rem 0',
          fontSize: '0.857rem',
        },
        dropDownIcon: {
          width: '1.714rem',
          height: '1.714rem',
        },
      },
    },
  },
  searchPage: {
    ...giftVouchersConfig.searchPage,
    modal: {
      style: {
        ...giftVouchersConfig.searchPage.modal?.style,
        background: 'var(--surface-a)',
      },
    },
    modalContent: {
      style: {
        ...giftVouchersConfig.searchPage.modalContent?.style,
      },
    },
  },
  header: {
    ...giftVouchersConfig.header,
    container: {
      style: {
        ...giftVouchersConfig.header.container?.style,
        paddingRight: '5rem',
        paddingLeft: '5rem',
      },
    },
    logo: {
      ...giftVouchersConfig.header.logo,
      style: {
        width: '50%',
        marginTop: '8px',
      },
    },
    backBtnContainer: {
      style: {
        ...giftVouchersConfig.header.backBtnContainer.style,
      },
    },
  },
  checkout: {
    ...giftVouchersConfig.checkout,
    style: {
      ...giftVouchersConfig.checkout.style,
      buyButton: {
        ...giftVouchersConfig.checkout.style.buyButton,
      },
    },
  },
  giftcardRedemption: {
    ...giftVouchersConfig.giftcardRedemption,
    amountQuantityComponent: {
      ...giftVouchersConfig.giftcardRedemption.amountQuantityComponent,
      style: {
        ...giftVouchersConfig.giftcardRedemption.amountQuantityComponent?.style,
        addMoreButton: {
          ...giftVouchersConfig.giftcardRedemption.amountQuantityComponent
            ?.style?.addMoreButton,
          alignSelf: 'start',
        },
      },
    },
  },
  orderStatus: {
    ...giftVouchersConfig.orderStatus,
    card: {
      ...giftVouchersConfig.orderStatus.card,
      container: {
        ...giftVouchersConfig.orderStatus.card.container,
      },
    },
  },
};

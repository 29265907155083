import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import {
  BookingResponse,
  Campaign,
  GiftCardBookingResponse,
  GiftCardRedemptionDetailsResponse,
  GiftCardRedemptionOptionsResponse,
  GiftcardDetailsFlatObj,
  RedemptionDetail,
  RedemptionDetails,
} from '../../interfaces/gv-redemption';

@Injectable({
  providedIn: 'root',
})
export class CampaignService {
  public redeemGv: EventEmitter<RedemptionDetails | false> = new EventEmitter();

  public constructor(
    private http: HttpClient,
    private messageService: MessageService
  ) {}

  public getCampaignGiftcardRedemptions(
    campaignId: string
  ): Observable<GiftCardRedemptionOptionsResponse> {
    return this.http.get<GiftCardRedemptionOptionsResponse>(
      environment.campaignPath +
        `campaigns/` +
        campaignId +
        `/gift_card_redemption_options`
    );
  }

  public createGvDetails(giftcard: RedemptionDetail): GiftcardDetailsFlatObj {
    const giftcardDetails =
      giftcard?.attributes?.gift_card_details.data?.attributes;

    return {
      id: giftcard.attributes.gift_card_redemption_option.data.id,
      imageUrl:
        giftcardDetails.image_url ??
        'https://images.poshvine.com/icon/gv/rectangular-image-placeholder.jpeg',
      title: giftcardDetails.title,
      isClaimed: this.isGiftcardClaimed(giftcard),
      merchantName: giftcardDetails?.merchant?.name || 'Unknown Merchant',
      description: giftcardDetails.description,
      redemption_steps: giftcardDetails.redemption_steps,
      terms: giftcardDetails.terms,
    };
  }

  public redeemGiftVoucher(
    campaignId: string,
    gvRedemptionId: string
  ): Observable<GiftCardBookingResponse> {
    return this.http.post<GiftCardBookingResponse>(
      `${environment.campaignPath}campaigns/${campaignId}/gift_card_redemption_options/${gvRedemptionId}/redeem`,
      {}
    );
  }

  public getCampaignRedemptions(
    campaignId: string
  ): Observable<BookingResponse> {
    return this.http.get<BookingResponse>(
      environment.campaignPath + `/user_redemptions`,
      {
        params: {
          campaign_ids: [campaignId],
          status: 'success',
        },
      }
    );
  }

  public getCampaignGiftcardDetails(
    campaignId: string,
    gvRedemptionId: string
  ): Observable<GiftCardRedemptionDetailsResponse> {
    return this.http.get<GiftCardRedemptionDetailsResponse>(
      `${environment.campaignPath}campaigns/${campaignId}/gift_card_redemption_options/${gvRedemptionId}`
    );
  }

  public getCampaigns(): Observable<{ data: Campaign[] }> {
    return this.http.get<{ data: Campaign[] }>(
      environment.campaignPath + '/campaigns?with_expired_benefits=true'
    );
  }

  public showErrorMessage(message: string): void {
    this.messageService.add({
      severity: 'error',
      summary: message,
    });
  }

  public isGiftcardClaimed(giftcard: RedemptionDetail): boolean {
    return (
      !giftcard.attributes.campaign_claimable.claimable &&
      giftcard.attributes.campaign_claimable.non_claimable_reason ===
        'already claimed!'
    );
  }
}

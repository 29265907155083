import { GiftVouchersConfig } from '@pv-frontend/gift-voucher-journey';
import { OverrideStyleConfig } from '@pv-frontend/pv-shared-components/common/types';

import { redeemButtonStyle } from './buttons.config';
import { chipsStyles } from './chips.config';
import { navBarStyles } from './navbar.config';
import { offerCardStyles } from './offer-card.config';

// TODO: need to keep the interface in one place
export const panelStyles: {
  container: OverrideStyleConfig;
  dropDownIcon: OverrideStyleConfig;
} = {
  container: {
    borderRadius: '1.143rem',
    background: 'var(--surface-a)',
    boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.16)',
    color: 'var(--black-teritiary)',
    overflow: 'hidden',
    marginBottom: '1rem',
    padding: '0.857rem 0',
    fontSize: '0.857rem',
  },
  dropDownIcon: {
    width: '1.714rem',
    height: '1.714rem',
  },
};

export const giftVouchersConfig: GiftVouchersConfig = {
  global: {
    config: {
      imagePlaceholder:
        'https://images.poshvine.com/icon/gv/rectangular-image-placeholder.jpeg',
      isCardBasedLogin: false,
      includeLedger: true,
      hideLogoutBtn: false,
      coinIconName: 'jana-point',
      offerButtonLabel: 'CLAIM',
      includeLoginFlow: true,
      pointsIconFirst: true,
      allowedPaymentMethods: ['point_redemption'],
      earnMore: {
        isExternalUrl: false,
        url: '/earn-more',
      },
      offerClaimableByPoints: false,
      redemptionValueRoundingMethod: 'floor',
    },
    style: {
      chevronDown: {
        width: '1.4375rem',
        height: '1.4375rem',
        fill: 'var(--brand-primary-500)',
      },
      offerCountTag: {
        tag: {
          padding: '0 1rem',
          backgroundColor: 'var(--primary-color-hover)',
          opacity: '.8',
          height: '22px',
        },
        label: {
          fontSize: '12px',
        },
      },
    },
    navBar: {
      backIcon: {
        style: {
          margin: 'auto',
          fill: 'var(--primary-color)',
        },
      },
    },
  },
  voucherCard: {
    voucher: {},
    container: {
      style: {
        success: {
          background:
            'linear-gradient(107deg, #D8107B 1.55%, #D8107B 1.56%, #76194B 53.6%)',
          borderRadius: '12px',
        },
        error: {
          borderRadius: '12px',
          backgroundColor: 'var(--error-background-low-contrast)',
          border: '1px solid var(--error-red)',
          fill: 'var(--error-red)',
          color: 'var(--text-color)',
        },
      },
    },
    redeemButton: {
      style: {
        padding: '0',
        textDecoration: 'underline',
        fontSize: '1rem',
        color: '#F8F9FB',
        minWidth: 'auto',
        fontWeight: 600,
        textTransform: 'uppercase',
      },
    },
    statusIcon: {
      style: {
        width: '1.14286rem',
        height: '1.14286rem',
      },
    },
    divider: {
      style: {
        width: 'calc(100% + 2rem)',
        margin: '1.4rem -1rem 1.9rem',
      },
    },
  },
  landingPage: {
    showLoginAlert: false,
    sectionOrder: [
      'BANNERS',
      'SEARCH_BAR',
      'CATEGORIES',
      // 'PERSONALIZED_OFFERS',
      // 'LOGIN_BANNER',
      // 'LIMITED_TIME_OFFERS',
      // 'MID_BANNERS',
      'MOST_SEARCHED_OFFERS',
      // 'BEST_SELLER_OFFERS',
      'TOP_MEMBERSHIPS',
      'TOP_CATEGORY_BASED_OFFERS',
      'OFFERS',
    ],
    viewAllButton: {
      style: {
        borderRadius: '32px',
        width: '100px',
        height: '40px',
      },
      config: {
        label: 'VIEW ALL',
      },
    },
    personalisedCardSectionStyle: {
      style: {
        backgroundImage: {
          position: 'absolute',
          top: '-15px',
          right: '1rem',
          transform: 'rotate(0deg)',
          width: '6rem',
          height: '6rem',
        },
        title: {
          image: {
            fill: 'var(--green-600)',
            'margin-right': '.2rem',
            height: '1.5rem',
          },
        },
        link: {},
        card: {
          'background-color': '#FCF4E8',
          margin: '1.5rem 0 0',
          padding: '0 0.5rem 1.5rem',
        },
      },
    },
    personalisedCardStyle: {
      cardContainer: {
        width: '100% !important',
        height: '100% !important',
        border: '0',
      },
      image: {
        minHeight: '107px',
        maxHeight: '6.938rem',
      },
      cardContent: {
        border: '.4px solid var(--feedback-border-notice-high-contrast)',
        'border-top': '0',
      },
    },
    limitedTimeCardSectionStyle: {
      style: {
        backgroundImage: {},
        title: {
          image: {
            'margin-right': '.2rem',
            height: '1.5rem',
          },
        },
        link: {
          'font-weight': '700',
          'font-size': '1rem',
        },
        card: {
          'background-color': '#fff',
          margin: '0',
          padding: '.3rem 0 1.5rem .5rem',
        },
      },
    },
    limitedTimeCardStyle: {
      style: {
        cardContainer: {
          'min-height': '15rem',
          border: '.4px solid var(--feedback-border-information-high-contrast)',
        },
        title: {
          color: 'var(--surface-text-normal-low-contrast)',
        },
        actionButton: {
          'font-weight': '700',
        },
      },
    },
    bestSellerCardSectionStyle: {},
    bestSellerCardStyle: {
      style: {
        cardContainer: {
          width: '100% !important',
          height: '100% !important',
          border: '.4px solid var(--feedback-border-positive-high-contrast)',
        },
        title: {
          color: 'var(--surface-text-normal-low-contrast)',
        },
        cardContent: {
          padding: '0.75rem 0.75rem 2rem',
        },
        footer: {
          background:
            'linear-gradient(90deg, rgba(0, 140, 177, 0.12) 13.92%, rgba(0, 140, 177, 0) 111.39%)',
          position: 'absolute',
          width: '100%',
          bottom: '0',
          'font-size': '.75rem !important',
        },
      },
    },
    categoryOfferSectionStyle: {
      style: {
        backgroundImage: {
          position: 'absolute',
          top: '-15px',
          right: '1rem',
          transform: 'rotate(0deg)',
          width: '7.143rem',
          height: '7.143rem',
        },
        title: {
          image: {
            height: '1.429rem',
            width: '1.429rem',
            fill: '#ABEFCF',
          },
          container: {
            fontSize: '1rem',
            paddingLeft: '0.5rem',
          },
        },
        link: {
          'font-weight': '700',
          'font-size': '17px',
          background: 'transparent',
        },
        card: {
          backgroundColor: 'var(--background-tertiary-color)',
          margin: '0',
          padding: '0.5rem .5rem 2rem',
        },
      },
      config: {
        buttonLabel: 'VIEW ALL',
      },
    },
    categoryOfferCard: {
      style: {
        cardContainer: {
          width: '100% !important',
          height: '100% !important',
          borderRadius: '0.714rem',
          // border: '0.4px solid #1D6266',
        },
        cardContent: {
          borderRadius: '0 0 .7rem .7rem',
          // border: '.4px solid var(--surface-border-subtle)',
          // borderTop: '0',
        },
        image: {
          minHeight: '107px',
          borderRadius: '0.7rem 0.7rem 0 0',
          maxHeight: '6.938rem',
        },
        divider: {
          opacity: '.4',
        },
        title: {
          fontWeight: '600',
        },
        information: {
          baseText: {
            fontSize: '12px',
            color: 'var(--primary-color)',
          },
          attentionText: {
            fontSize: '12px',
            color: 'var(--primary-color)',
          },
        },
      },
    },
    topMembershipSectionStyle: {
      style: {
        backgroundImage: {
          top: '-15px',
          right: '1rem',
          transform: 'rotate(0deg)',
          width: '6rem',
          height: '6rem',
        },
        title: {
          image: {
            width: '20px',
            height: '20px',
            fill: '#174A4D',
          },
          container: {
            fontSize: '1rem',
            paddingLeft: '1rem',
          },
        },
        card: {
          backgroundColor: 'var(--background-secondary-color)',
          margin: 0,
          paddingTop: '0.8rem',
          paddingBottom: '2rem',
        },
      },
      config: {
        buttonLabel: 'View All',
        emphasizedTitleText: 'Top memberships for you',
      },
    },
    topMembershipCardStyle: {
      style: {
        cardContainer: {
          width: '100% !important',
          height: '100% !important',

          borderRadius: '0.714rem',
          border: '0.4px solid var(--primary-color)',
          maxWidth: '50%',
        },
        title: {
          color: 'var(--gray-secondary)',
          fontSize: '11px',
          fontWeight: '400',
        },
        subTitle: {
          fontSize: '12px',
          color: 'var(--text-color)',
          fontWeight: '600',
        },
        cardContent: {
          borderTop: '0',
          borderRadius: '0 0 0.7rem 0.7rem',
          padding: '0.857rem 0.857rem 1.143rem 0.857rem ',
        },
        image: {
          minHeight: '107px',
          borderRadius: '0.7rem 0.7rem 0 0',
          maxHeight: '6.938rem',
        },
        divider: {
          opacity: '.4',
        },
        information: {
          strikeThroughText: {
            color: 'var(--gray-secondary)',
            fontSize: '10px',
          },
          baseText: {
            fontSize: '12px',
            color: 'var(--primary-color)',
          },
          attentionText: {
            fontSize: '12px',
            color: 'var(--primary-color)',
            fontWeight: '700',
          },
        },
      },
    },
    mostSearchedSectionStyle: {
      style: {
        backgroundImage: {},

        title: {
          container: {
            paddingLeft: '1rem',
            fontSize: '1rem',
          },
          image: {
            height: '1.5rem',
            width: '1.5rem',
            fill: '#55AAFB',
          },
        },
        link: {
          fontSize: '1rem',
        },
        card: {
          backgroundColor: 'var(--surface-a)',
          margin: '1rem 0 0',
          padding: '0',
        },
      },
      config: {
        buttonLabel: 'VIEW ALL',
      },
    },
    merchantAvatarStyle: {
      style: {
        label: {
          width: '100%',
          fontSize: '12px',
          color: 'var(--gray-secondary)',
          textoverflow: 'ellipsis',
          paddingTop: '4px',
          wordWrap: 'break-word',
          webkitLineClamp: '2',
        },
        imageOrInitialsContainer: {
          container: {
            width: '52px',
            height: '52px',
          },
          image: {
            objectFit: 'cover',
            // border: '0.4px solid var(--text-color-secondary)'
          },
        },
      },
    },
    membershipTag: {
      position: 'right',
      style: {
        tag: {
          backgroundColor: '#FF9659',
          padding: '0.5rem',
          opacity: '0.8',
        },
        label: {
          'font-size': '0.71429rem',
        },
      },
      label: 'Membership',
    },
    loginAlertDarwer: {
      style: {
        'box-shadow': 'unset',
        background: 'transparent',
        padding: '0',
      },
    },
    categoryAvatarStyle: {
      label: {
        width: '100%',
        fontSize: '1rem',
        color: 'var(--gray-secondary)',
        textoverflow: 'ellipsis',
        paddingTop: '4px',
        wordWrap: 'break-word',
        webkitLineClamp: '2',
      },
      container: {
        paddingLeft: '0px',
        minWidth: '70px',
      },
      imageOrInitialsContainer: {
        container: {
          width: '52px',
          height: '52px',
        },
      },
    },
    banners: {
      config: {
        data: [
          {
            imageUrl:
              'https://images.poshvine.com/banners/Exclusive+-+Taj+Epicure_320x180+px.webp',
          },
        ],
        showPageTopBanner: true,
        showPageMiddleBanner: true,
      },
    },
    topBannersCarouselOptions: [
      {
        breakpoint: '1280',
        numVisible: 1.1,
        numScroll: 1,
      },
      {
        breakpoint: '991px',
        numVisible: 1.1,
        numScroll: 1,
      },
      {
        breakpoint: '767px',
        numVisible: 1.05,
        numScroll: 1,
      },
    ],
    midBannersCarouselOptions: [
      {
        breakpoint: '1280',
        numVisible: 1.2,
        numScroll: 1,
      },
      {
        breakpoint: '991px',
        numVisible: 1.2,
        numScroll: 1,
      },
      {
        breakpoint: '767px',
        numVisible: 1.2,
        numScroll: 1,
      },
    ],
    limitedCarouselOptions: [
      {
        breakpoint: '767px',
        numVisible: 2.5,
        numScroll: 1,
      },
      {
        breakpoint: '500px',
        numVisible: 2.2,
        numScroll: 1,
      },
      {
        breakpoint: '400px',
        numVisible: 2.2,
        numScroll: 1,
      },
      {
        breakpoint: '340px',
        numVisible: 2,
        numScroll: 1,
      },
    ],
    bestSellerCarouselOptions: [
      {
        breakpoint: '767px',
        numVisible: 2.5,
        numScroll: 1,
      },
      {
        breakpoint: '500px',
        numVisible: 2.2,
        numScroll: 1,
      },
      {
        breakpoint: '400px',
        numVisible: 2.2,
        numScroll: 1,
      },
      {
        breakpoint: '340px',
        numVisible: 2,
        numScroll: 1,
      },
    ],
    searchBar: {
      style: {
        container: {
          border: '1px solid var(--surface-text-subdued-high-contrast)',
          borderRadius: '107px',
        },
        searchIcon: {
          fill: 'var(--surface-action-icon-low-contrast)',
        },
        input: {
          height: '24px',
          fontSize: '0.875rem',
        },
      },
      config: {
        placeholder: 'Search via category, brand or product',
      },
    },
    offersSectionStyle: {
      style: {
        cardSection: {
          backgroundImage: {
            position: 'absolute',
            top: '-.9rem',
            right: '1rem',
            transform: 'rotate(0deg)',
            width: '7rem',
            height: '7rem',
          },
          title: {
            image: {
              height: '1.429rem',
              width: '1.429rem',
              fill: '#ABEFCF',
            },
          },
          card: {
            backgroundColor: 'var(--card-background-varient-1)',
            margin: '0',
            padding: '.6rem 0.6rem 1.5rem',
          },
        },
        categoryBasedOffers: {
          chipContainer: {
            style: {
              marginRight: '-0.5rem',
            },
          },
          card: {
            style: offerCardStyles,
          },
          chip: {
            style: chipsStyles,
          },
        },
      },
    },
  },
  listing: {
    pageLayout: {
      chipType: 'module',
      chipOptions: [
        {
          displayName: 'Gift cards',
          paramName: 'GiftCard',
        },
        {
          displayName: 'Memberships',
          paramName: 'Membership',
        },
        {
          displayName: 'Offers',
          paramName: 'offers',
        },
      ],
    },
    personalisedCardSectionStyle: {
      style: {
        backgroundImage: {
          position: 'absolute',
          top: '-.9rem',
          right: '1rem',
          transform: 'rotate(0deg)',
          width: '7rem',
          height: '7rem',
        },
        title: {
          image: {
            fill: 'var(--surface-text-normal-low-contrast)',
            'margin-right': '.2rem',
            width: '1rem',
          },
        },
        card: {
          'background-color': '#fff',
          margin: '.5rem 0 0',
          padding: '0 0.7rem 1.5rem',
        },
      },
    },
    card: {
      style: offerCardStyles,
    },
    chip: {
      style: chipsStyles,
    },
    membershipTag: {
      position: 'right',
      style: {
        tag: {
          backgroundColor: 'hsla(35, 84%, 54%, .8)',
          padding: '0.5rem',
        },
        label: {
          fontSize: '0.71429rem',
        },
      },
      label: 'Membership',
    },
    navBar: {
      style: {
        container: navBarStyles.container,
        backIcon: navBarStyles.backIcon,
      },
    },
  },
  shellListing: {},
  benefitDetails: {
    backButton: {
      style: {
        fill: 'var(--surface-a)',
        background: 'rgba(0, 0, 0, .1)',
        width: '32px',
        height: '32px',
        padding: '6px 8px',
        borderRadius: '50%',
      },
    },
    buyButton: {
      style: redeemButtonStyle,
    },
    title: {
      style: {
        color: 'var(--text-color)',
        fontWeight: '600',
        fontSize: '1.143rem',
      },
    },
    description: {
      style: {
        color: 'var(--gray-secondary)',
      },
    },
    panel: {
      style: panelStyles,
    },
  },
  offerDetails: {
    config: {
      redeemButtonLabel: 'Redeem Now',
      claimButtonLabel: 'Claim Now',
    },
    style: {
      panelContainer: panelStyles.container,
      panelDropDownIcon: panelStyles.dropDownIcon,
      redeemButton: redeemButtonStyle,
      copyIcon: {
        copyIconStyle: {
          width: '16px',
          height: '16px',
          marginTop: '3px',
          fill: 'var(--primary-color)',
        },
      },
      footerContainer: {
        boxShadow: '0px 0px 1px 0px var(--black-teritiary)',
      },
      backIcon: {
        width: '20px',
        height: '20px',
        fill: 'var(--primary-color)',
      },
      backButtonContainer: {
        backgroundColor: 'unset',
        padding: 0,
        minWidth: 0,
      },
      expiry: {
        ...panelStyles.container,
        padding: '24px 1rem',
        fontSize: '1rem',
      },
    },

    header: {
      style: {
        avatar: {
          container: {
            width: '4.5rem',
            height: '4.5rem',
          },
          imageOrInitialsContainer: {
            container: {
              width: '100%',
              height: '100%',
              borderRadius: '43.242px',
            },
          },
        },
        merchantName: {
          fontSize: '16px',
        },
      },
    },
    offerSelection: {
      style: {
        avatar: {
          imageOrInitialsContainer: {
            image: {
              borderRadius: '37px',
              width: '36px',
              height: '36px',
            },
          },
        },
        offerOutline: {
          borderRadius: '1.143rem',
          padding: '1.143rem',
          boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.16)',
        },
        infoContainer: {
          width: 'calc(100% - 36px)',
        },
        claimButton: {
          color: 'var(--primary-color)',
          letterSpacing: '0.085rem',
          textTransform: 'uppercase',
          background: 'var(--surface-a)',
          fontSize: '0.875rem',
          padding: '0',
          textAlign: 'center',
          lineHeight: '16px',
          fontWeight: '700',
        },
        offerTitle: {
          fontSize: '0.857rem',
          color: 'var(--text-color)',
          fontWeight: '600',
        },
        offerDescription: {
          fontSize: '0.857rem',
          color: 'var(--gray-secondary)',
          fontWeight: '400',
        },
      },
    },
  },
  header: {
    container: {
      style: {
        background: 'var(--primary-color)',
        boxShadow: '0px 1px 3px 0px var(--card-shadow)',
      },
    },
    logo: {
      config: {
        url: 'https://images.poshvine.com/logos/jana-small-finance-bank-limited-logo-vector 1.svg',
      },
      style: {
        width: '169px',
        height: '56px',
      },
    },
    userName: {
      style: {
        color: 'var(--surface-a)',
      },
    },
    userProfile: {
      config: {
        type: 'name',
      },
    },
    overlay: {
      style: {
        right: '0',
        padding: '0',
        width: '22rem',
        borderRadius: '0',
        marginTop: '14px',
        boxShadow: 'none',
      },
    },
    divider: {
      style: {
        border: '0',
        borderTop: '.7px solid var(--surface-action-icon-low-contrast)',
      },
    },
    logout: {
      style: {
        padding: '0',
        minWidth: 'auto',
      },
    },
    panel: {
      style: {
        container: {
          background: 'transparent',
          boxShadow: 'unset',
          lineHeight: '1.5',
        },
        dropDownIcon: {
          width: '.875rem',
          height: '.875rem',
        },
      },
    },
    loginButton: {
      style: {
        color: 'var(--surface-a)',
        fontSize: '14px',
        fontWeight: '700',
        background: 'var(--primary-color)',
      },
    },
    searchIcon: {
      style: {
        height: '1.1rem',
        width: '1.1rem',
        fill: 'var(--brand-gray-200-low-contrast)',
      },
      config: {
        imageSrc: 'search',
      },
    },
    points: {
      style: {
        color: '#fff',
      },
    },
    dropdown: {
      style: {
        links: {
          color: 'var(--gray-secondary)',
        },
      },
    },
    coinIcon: {
      style: {
        width: '15px',
        // marginTop: '1px',
        marginTop: '2px',
        // marginRight: '-4px',
      },
    },
    backBtnContainer: {
      style: {
        cursor: 'pointer',
      },
    },
    backBtn: {
      style: {
        width: '32px',
        height: '32px',
        margin: '0 auto',
        fill: 'var(--primary-color)',
        boxShadow: '0px 1px 3px 0px var(--card-shadow)',
        padding: '6px 8px',
        borderRadius: '50%',
        background: 'var(--neutral-white)',
      },
      config: {
        imageSrc: 'back',
      },
    },
    avatar: {
      style: {
        container: {
          border: '.8px solid var(--surface-background-level1-low-contrast)',
          width: '1.71429rem',
          height: '1.71429rem',
          margin: '0',
          borderRadius: '50%',
        },
        label: {
          color: '#fff',
          fontSize: '0.71429rem',
          fontWeight: '700',
        },
        imageOrInitialsContainer: {
          initials: {
            background: 'var(--primary-color)',
            fontSize: '0.71429rem',
          },
          container: {
            width: '100%',
            height: '100%',
          },
        },
      },
    },
  },
  searchPage: {
    modal: {
      style: {
        padding: 0,
        background: 'var(--primary-color)',
      },
    },
    modalContent: {
      style: {
        background: 'white',
      },
    },
    searchResult: {
      config: {
        maxSearchResultsToShow: 20,
        apiErrorHeading: 'Uh oh! Failed to load',
        apiErrorDescription:
          'Something went wrong while connecting to the API. Please reload the page',
        apiErrorButtonLabel: 'Retry',
      },
      style: {
        apiErrorButton: {
          width: '200px',
          height: '48px',
        },
        apiErrorButtonSize: 'large',
        emptySearchImage: {
          width: '100px',
        },
      },
    },
    searchBar: {
      style: {
        container: {
          background: 'white',
        },
        searchIcon: {
          fill: 'var(--surface-action-icon-low-contrast)',
        },
        input: {
          height: '24px',
          fontSize: '0.875rem',
        },
      },
      config: {
        placeholder: 'Search via category, brand or product',
      },
    },
    recommendedBrands: {
      style: {
        container: {
          height: '100%',
          width: '60px',
        },
        label: {
          fontSize: '12px',
          wordWrap: 'break-word',
          webkitLineClamp: '2',
          color: 'var(--gray-secondary)',
        },
        imageOrInitialsContainer: {
          image: {
            objectFit: 'cover',
          },
        },
      },
      config: {
        heading: 'Recommended brands',
        maxRecommendedBrandsToShow: 8,
      },
    },
    pastSearch: {
      config: {
        heading: 'Past Searches',
        maxSearchHistoryToShow: 3,
      },
    },
  },
  authentication: {
    authenticationFlow: {
      config: {
        flow: ['MOBILE_SEARCH', 'OTP_VERIFICATION'],
      },
    },
    drawer: {
      style: {},
      config: {
        dismissOnBackdropClick: true,
      },
    },
    emailSearch: {
      config: {
        skipEmailSearch: true,
        showBackIcon: false,
        otpTriggerFailureMessage: '',
      },
    },
    mobileSearch: {
      config: {
        skipMobileSearch: false,
        heading: 'Enter your registered mobile number',
        primaryHeading: 'Verify Mobile Number',
        mobileSearchErrorMessage: 'Please enter the registered mobile number',
        userCreationErrorMessage: 'Please enter a valid number',
        otpTriggerFailureMessage:
          'You have exceeded the maximum limit for OTP requests. Please try again after some time.',
        submitButtonLabel: 'CONTINUE',
        showBackIcon: false,
        shouldCreateUser: false,
        isExternalIdRequired: false,
        consentPrompt: {},
        isCountrySelectEnabled: true,
        placeholder: 'Enter your mobile number',
      },
      style: {
        primaryHeading: {
          fontSize: '16px',
          fontWeight: 700,
        },
        mobileInput: {
          container: {
            display: 'block',
            width: '100%',
          },
          inputBox: {
            height: '48px',
          },
          input: {
            fontSize: '1rem',
          },
          error: {
            marginTop: '8px',
            flexFlow: 'wrap',
            display: 'flex',
            width: '100%',
          },
        },
        label: {
          width: '100% !important',
          textAlign: 'left',
          fontWeight: 400,
          color: 'unset',
          fontSize: '0.875rem',
          marginRight: '20px',
        },
        labelContainer: {
          width: '100%',
          textAlign: 'center',
        },
        button: {
          fontSize: '16px',
          width: '60%',
          margin: 'auto',
        },
      },
    },
    binValidation: {
      config: {
        heading: '',
        shouldValidate: false,
        errorMessage: '',
        binType: 'first4',
        submitButtonLabel: '',
        binSeparator: '',
      },
    },
    otpVerification: {
      config: {
        skipOtpVerification: false,
        heading: 'Please enter the 6 digit OTP',
        errorMessage: 'Invalid OTP. Please enter the correct OTP.',
        resendOtpLabel: 'Did not receive OTP yet?',
        editMobileBtnLabel: 'Change number ?',
        countdownValue: 150,
        submitButtonLabel: 'Verify OTP',
        showNumberChange: true,
      },
      style: {
        resentOtpLabel: {
          width: '2rem',
        },
        otpInput: {
          input: {
            width: 'inherit',
            maxWidth: '4rem',
          },
          container: {
            width: '100%',
          },
          inputContainer: {
            width: '100%',
          },
          label: {
            fontSize: '0.875rem',
          },
        },
        countdown: {
          container: {
            fontSize: '0.75rem',
          },
        },
        button: {
          fontSize: '16px',
          width: '60%',
          margin: 'auto',
        },
      },
    },
    userCardSelection: {
      style: {
        continueButton: {
          borderRadius: '.125rem',
        },
        heading: {
          fontSize: '0.875rem',
        },
        cardTitle: {
          '-webkit-line-clamp': 2,
        },
        cardTitleClasses: 'pv-line-clamp-1',
      },
      config: {
        skipCardSelection: false,
        heading: 'Select your card',
        allowAddCard: false,
        addNewCardLabel: 'Add new card',
      },
    },
  },

  redemption: {
    cardDropdown: {
      style: {
        width: '100%',
        borderRadius: '50px',
        fontSize: '0.875rem',
        height: '44px',
      },
    },
    benefitHeader: {
      style: {
        benefitTitle: {
          fontSize: '17px',
          color: 'var(--text-color)',
        },
        knowMoreButton: {
          fontWeight: 700,
          fontSize: '1rem',
        },
        redemptionChannel: {
          fontSize: '1rem',
        },
      },
    },
  },
  checkout: {
    style: {
      merchantName: {
        fontSize: '14px',
        color: 'var(--text-color)',
      },
      paymentMethodNote: {
        display: 'none',
      },
      paymentMethodAvailablePointsIcon: {
        width: '12px',
        height: '12px',
      },
      buyButton: {
        // TODO: use from button config
        // width: '200px',
        // height: '100%',
        padding: '1.5rem',
        borderRadius: '2.2rem',
        boxShadow: '0px 2px 8px 0px var(--button-box-shadow)',
        textTransform: 'uppercase',
        fontSize: '17px',
      },
      burnRate: {
        container: {
          display: 'none',
        },
      },
      paymentMethodTitle: {
        color: 'var(--text-color)',
        fontSize: '1rem',
      },
      paymentMethodRedemptionValue: {
        color: 'var(--gray-secondary)',
        display: 'none',
      },
      paymentMethodRedemptionText: {
        display: 'none',
      },
      paymentMethodAvailablePointsText: {
        color: 'var(--gray-secondary)',
      },
      paymentMethodAvailablePoints: {
        color: 'var(--text-color)',
      },
      billDetailsHeader: {
        color: 'var(--text-color)',
      },
      billDetailsTotalLabel: {
        color: 'var(--gray-secondary)',
      },
      billDetailsTotalValue: {
        color: 'var(--text-color)',
      },
      billDetailsRewardsUsedValue: {
        color: '#16A160',
      },
      billDetailsEffecSavingsValue: {
        color: '#16A160',
      },
    },
    config: {
      paymentMethodContainerHeading: 'Payment method',
      payUsingCardTitle: 'Pay using Cards/Wallet/UPI',
      payWithPointsAndCardTitle: 'Pay using your Points',
      rewardsPointsUsedDesc: 'Used points',
      availablePointsDesc: 'Available points',
      valueOfRewardPointsLabel: 'Value of Reward Points',
      billDetailsHeading: 'Bill Details',
      billDetailsGcTotalLabel: 'Giftcard Total',
      billDetailsMemTotalLabel: 'Membership Total',
      redeemablePointsDesc: 'Reedemable points',
    },
  },
  giftcardRedemption: {
    footer: {
      style: {
        buyButton: {
          // TODO: use from button config
          width: '200px',
          height: '48px',
          borderRadius: '2.2rem',
          boxShadow: '0px 2px 8px 0px var(--button-box-shadow)',
          textTransform: 'uppercase',
          fontSize: '17px',
        },
        circleCheckIcon: {
          fill: 'var(--feedback-background-positive-high-contrast )',
          width: '0.75rem',
          height: '1rem',
        },
      },
    },
    amountQuantityComponent: {
      style: {
        addMoreButton: {
          background: 'transparent',
          borderRadius: '32px',
          fontSize: '17px',
        },
        incrementDecrement: {
          container: {
            border: 'none',
          },
        },
      },
      config: {
        maximumOrderValueAllowed: 5,
        maxAllowedQuantityPerDenomination: 5,
        maxAllowedUniqueDenominations: 4,
      },
    },
  },
  membershipRedemption: {
    footer: {
      style: {
        buyButton: {
          // TODO: use from button config
          width: '200px',
          height: '48px',
          borderRadius: '2.2rem',
          boxShadow: '0px 2px 8px 0px var(--button-box-shadow)',
          textTransform: 'uppercase',
          fontSize: '17px',
        },
        circleCheckIcon: {
          fill: 'var(--feedback-background-positive-high-contrast )',
          width: '0.75rem',
          height: '1rem',
        },
      },
      config: {},
    },
  },
  orderDetails: {
    metaInfoDrawerPanel: {
      style: {
        container: {
          padding: '2rem',
        },
        header: {},
        content: {},
      },
    },
    buyButton: {
      style: redeemButtonStyle,
    },
    learnButton: {
      style: {
        color: 'var(--brand-primary-500)',
        padding: '0',
        marginBottom: '2rem',
      },
    },
    statusIcon: {
      style: {
        width: '1.14286rem',
        height: '1.14286rem',
      },
    },
    topBar: {
      style: {
        boxShadow: '0px 1px 3px 0px var(--card-shadow)',
        position: 'relative',
        fontSize: '17px',
        color: 'var(--text-color)',
        fontWeigth: '700',
      },
    },
    backArrow: {
      style: {
        fill: 'var(--primary-color)',
        width: '2.25rem',
        height: '2.25rem',
        padding: '9px',
        display: 'flex',
        borderRadius: '50%',
        boxShadow: '0px 2px 8px 0px var(--card-shadow)',
      },
    },
    panel: {
      style: panelStyles,
    },
    homeNavigationPath: [''],
  },
  orderHistory: {
    pageLayout: {
      moduleChipsVisible: true,
      chipOptions: [
        {
          displayName: 'Exclusive Bookings',
          paramName: 'ExclusiveBooking',
        },
        {
          displayName: 'Gift Cards',
          paramName: 'GiftCardBooking',
        },
        {
          displayName: 'Memberships',
          paramName: 'MembershipBooking',
        },
        {
          displayName: 'Offers',
          paramName: 'OfferBooking',
        },
      ],
      dateFormat: 'dd MMM yyyy',
    },
    navBar: {
      style: {
        backgroundColor: 'white',
        boxShadow: '0px 1px 3px 0px var(--card-shadow)',
        position: 'relative',
        zIndex: '1',
        fontSize: '17px',
        fontWeight: '700',
      },
    },
    chip: {
      style: chipsStyles,
    },
    backArrow: {
      style: {
        fill: 'var(--primary-color)',
        width: '35px',
        height: '35px',
        boxShadow: '0px 2px 8px 0px var(--card-shadow)',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '10px',
      },
      navigationPath: ['gvms'],
    },
    navBarTitle: {
      style: {
        color: '#404040',
        fontSize: '17px',
        fontWeight: '700',
      },
    },
    statusIcon: {
      style: {
        width: '0.85714rem',
        height: '0.85714rem',
      },
    },
    benefitButton: {
      style: {
        // TODO: use from button config
        padding: '.2rem 0',
        fontWeight: '600',
        fontSize: '.86rem',
        color: 'var(--brand-primary-500)',
        borderRadius: '2.2rem',
        boxShadow: '0px 2px 8px 0px var(--button-box-shadow)',
        textTransform: 'uppercase',
        width: '75px',
      },
    },
    card: {
      card: {
        padding: '0.7rem 0',
      },
      title: {
        fontWeight: '700',
        fontSize: '1rem',
        color: 'var(--text-color)',
      },
    },
    divider: {
      style: {
        border: '0',
        borderTop: '.7px solid var(--feedback-border-neutral-low-contrast)',
      },
    },
  },
  orderStatus: {
    buyButton: {
      style: redeemButtonStyle,
    },
    benefitButton: {
      // TODO: use from button config
      style: {
        padding: '.2rem 0',
        fontWeight: '700',
        fontSize: '.86rem',
        color: 'var(--primary-color)',
        borderRadius: '2.2rem',
        boxShadow: '0px 2px 8px 0px var(--button-box-shadow)',
        textTransform: 'uppercase',
        width: '95px',
      },
    },
    card: {
      title: {
        fontWeight: '400',
        fontSize: '0.86rem',
        color: 'var(--text-color)',
      },
      description: {
        color: '#A6A6A6',
      },
      container: {
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
    },
    statusIcon: {
      style: {
        width: '3.42rem',
        height: ' 3.42rem',
      },
    },
    cardStatusIcon: {
      style: {
        lineHeight: '1.8',
      },
    },
    homeNavigationPath: [''],
  },
  paymentStatus: {
    style: {
      statusIcon: {
        success: {
          width: '72px',
          height: '72px',
          fill: 'var(--feedback-background-positive-high-contrast)',
        },
        error: {
          width: '70px',
          height: '70px',
          fill: 'var(--feedback-background-negative-high-contrast)',
        },
      },
    },
  },
  pointsLedger: {
    earnMore: {
      container: {
        config: {
          label: 'Available Jana Bank Points =',
          desc: 'Get exclusive offers, gift cards & much more',
          ctaLabel: 'Earn More',
          clipIconSrc: 'clip',
          heartIconSrc: 'jana-point',
        },
        cta: {
          style: {
            letterSpacing: '1px',
            textTransform: 'capitalize',
          },
        },
        label: {
          style: {
            color: 'var(--text-color)',
          },
        },
      },
      clipIcon: {
        style: {
          width: '39px',
          height: '53px',
        },
      },
      heartIcon: {
        style: {
          width: '26px',
          height: '24px',
          top: '13px',
          left: '8px',
        },
      },
    },
    container: {
      heading: {
        style: {
          fontSize: '12px',
          textTransform: 'uppercase',
        },
      },
    },
    listItemContainer: {
      label: {
        style: {
          fontWeight: '700',
          fontSize: '1rem',
          color: 'var(--text-color)',
        },
      },
      date: {
        style: {
          color: 'var(--gray-tertiary)',
          fontSize: '0.857rem',
        },
      },
    },
  },
  offersListing: {
    chip: {
      style: chipsStyles,
    },
    card: {
      style: offerCardStyles,
    },
    navBar: {
      style: {
        container: navBarStyles.container,
        backIcon: navBarStyles.backIcon,
      },
    },
  },
  tokenExpired: {
    config: {
      title: 'Your login token has expired',
      tokenExpiredIcon: 'hour-glass',
      description: 'Please re-authenticate to continue',
      reAuthenticateCtaButton: {
        isRedirectedFromApp: true,
        label: 'Back',
      },
    },
    style: {
      tokenExpiredIcon: {
        width: '60px',
        height: 'auto',
        fill: 'var(--primary-color)',
      },
      reAuthenticateCtaButton: {
        width: '160px',
        height: '50px',
      },
    },
  },
  loginBanner: {
    loginButton: {
      width: '9rem',
      borderRadius: '0',
      boxShadow: 'none',
      fontSize: '1rem',
      fontWeight: '700',
      height: '2.86rem',
    },
    iconStyle: {
      width: '8rem',
      height: '8rem',
    },
  },
  offerRedemption: {},
  bundleDetails: {},
};
